html,
body {
  padding: 0;
  margin: 0;
}

html {
  font-family: "Inter", Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;

  min-height: 100%;

  display: flex;
  flex-direction: column;
}

body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

#__next {
  flex: 1;
  display: flex;
  flex-direction: column;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
